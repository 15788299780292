export default {
    install(Vue, options) {
        Object.defineProperty(Vue.prototype, '$loading', {
            get() {
                const $loading = async (f) => {
                    this.$store.dispatch('loading/setLoading', true)
                    try {
                        await f()
                    } finally {
                        this.$store.dispatch('loading/setLoading', false)
                    }
                }
                $loading.loadContent = () => {
                    this.$store.dispatch('loading/startContentLoading')
                }
                return $loading;
            }
        });
    }
}