<template>
  <PageContent>
    <template #title>ユーザ管理</template>
    <SearchForm>
      <FormField labelWidth="4rem">
        <template #label>所属</template>
        <select v-model="currentGroup">
          <option :value="{type: 'admin'}">管理者</option>
          <option
            v-for="company in companies"
            :key="company.id"
            :value="{type: 'company', company: company}"
          >{{company.name}}</option>
        </select>
      </FormField>
      <template #buttons>
        <button type="button" @click="showForm()">新規登録</button>
      </template>
    </SearchForm>
    <table class="record-table" v-if="users">
      <tr>
        <th>メールアドレス</th>
        <th>ユーザ名</th>
        <th>所属</th>
        <th>編集</th>
      </tr>
      <tr v-for="user in users" :key="user.username">
        <td>{{user.email}}</td>
        <td>{{user.name}}</td>
        <td>
          <span v-if="user.role == 'admin'">管理者</span>
          <span v-if="user.company">{{user.company.name}}</span>
        </td>
        <td>
          <a href="javascript:void(0)" @click="showForm(user)">編集</a>
        </td>
      </tr>
    </table>
    <ModalDialog v-if="form.isShown">
      <form class="user-form" @submit.prevent="submitForm">
        <h3>
          <template v-if="form.user">編集</template>
          <template v-else>新規登録</template>
        </h3>
        <FormField>
          <template #label>メールアドレス</template>
          <input type="text" v-model="form.fields.email" />
        </FormField>
        <FormField>
          <template #label>ユーザ名</template>
          <input type="text" v-model="form.fields.name" />
        </FormField>
        <FormField>
          <template #label>所属</template>
          <span v-if="currentGroup.type == 'admin'">管理者</span>
          <span v-else>{{currentGroup.company.name}}</span>
        </FormField>
        <FormField>
          <template #label>パスワード</template>
          <template #note>6文字以上</template>
          <input type="text" v-model="form.fields.password" />
        </FormField>
        <div class="form-buttons">
          <button type="submit" :disabled="!formIsSubmittable">
            <template v-if="form.user">変更</template>
            <template v-else>登録</template>
          </button>
          <button type="button" class="sub" @click="closeForm">キャンセル</button>
        </div>
        <div class="delete-form" v-if="form.user">
          <a @click="confirmDelete">このユーザを削除する</a>
        </div>
        <p class="error-message" v-if="form.error">
          <template v-if="form.error == 'duplicate_email'">メールアドレスは既に登録されています</template>
          <template v-else-if="form.error == 'invalid_email'">メールアドレスが正しくありません</template>
          <template v-else>予期しないエラーが発生しました</template>
        </p>
      </form>
    </ModalDialog>
    <SimpleDialog
      :buttons="[
    {title: '削除', type: 'submit', click: submitDelete},
    {title: 'キャンセル', type: 'button', class: 'sub', click: cancelDelete}
    ]"
      v-if="deleteConfirmIsShown"
    >本当にユーザを削除しますか？</SimpleDialog>
  </PageContent>
</template>

<script>
import PageContent from "../components/page-content";
import SearchForm from "../components/search-form";
import ModalDialog from "../components/modal-dialog";
import FormField from "../components/form-field";
import SimpleDialog from "../components/simple-dialog";

const PASSWORD_REGEX = /^\S{6,99}$/;

export default {
  meta: {
    admin: true
  },
  metaInfo: {
    title: "ユーザ管理"
  },
  components: { PageContent, SearchForm, ModalDialog, FormField, SimpleDialog },
  data() {
    return {
      companies: [],
      currentGroup: { type: "admin" },
      users: null,
      form: {
        isShown: false,
        fields: null,
        user: null,
        error: null
      },
      deleteConfirmIsShown: false
    };
  },
  created() {
    this.$loading.loadContent();
  },
  async mounted() {
    await this.$loading(async () => {
      this.companies = await this.$api.get("/companies");
      await this.loadUsers();
    });
  },
  watch: {
    currentGroup() {
      this.$loading(async () => {
        await this.loadUsers();
      });
    }
  },
  computed: {
    formIsSubmittable() {
      return (
        this.form.fields.email.length &&
        this.form.fields.email.match(/.+@.+/) &&
        this.form.fields.name &&
        (this.form.user || this.form.fields.password.match(PASSWORD_REGEX))
      );
    }
  },
  methods: {
    async loadUsers() {
      try {
        this.users = await this.$api.get("/users", {
          params:
            this.currentGroup.type == "admin"
              ? { role: "admin" }
              : { companyId: this.currentGroup.company.id }
        });
      } catch (err) {
        this.users = [];
      }
    },
    showForm(user) {
      this.form.user = user;
      this.form.fields = {
        email: user?.email ?? "",
        name: user?.name ?? "",
        password: ""
      };
      this.form.isShown = true;
    },
    closeForm() {
      this.form = {
        isShown: false,
        fields: null,
        user: null
      };
    },
    async submitForm() {
      await this.$loading(async () => {
        try {
          const params = {
            email: this.form.fields.email,
            name: this.form.fields.name
          };
          let user;
          if (this.form.user) {
            user = this.form.user;
            await this.$api.put(`/users/${this.form.user.username}`, params);
          } else {
            user = await this.$api.post(
              "/users",
              Object.assign(params, {
                role: this.currentGroup.type == "admin" ? "admin" : "general",
                companyId: this.currentGroup.company?.id ?? null
              })
            );
          }
          if (this.form.fields.password) {
            await this.$api.put(`/users/${user.username}/password`, {
              password: this.form.fields.password
            });
          }
        } catch (err) {
          console.dir(err);
          this.form.error = err.response?.data.error ?? "unknown_error";
          return;
        }
        this.closeForm();
        this.loadUsers();
      });
    },
    confirmDelete() {
      this.deleteConfirmIsShown = true;
    },
    async submitDelete() {
      await this.$loading(async () => {
        await this.$api.delete(`/users/${this.form.user.username}`);
        this.deleteConfirmIsShown = false;
        this.closeForm();
        this.loadUsers();
      });
    },
    cancelDelete() {
      this.deleteConfirmIsShown = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.search-form {
  margin-bottom: 2rem;
}

.user-form {
  margin: 2rem;
  width: 24rem;
  h3 {
    margin-bottom: 2rem;
  }
  .form-field {
    margin-bottom: 1rem;
  }
  input {
    width: 100%;
  }
  .form-buttons {
    margin-top: 2rem;
    text-align: center;
    button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .delete-form {
    margin-top: 2rem;
    text-align: right;
    a {
      font-size: 0.8rem;
      color: $error-color;
    }
  }
  .error-message {
    margin-top: 1rem;
  }
}
</style>
