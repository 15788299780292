<template>
  <div class="form-field">
    <div class="form-field-label" :style="{width: labelWidth}">
      <slot name="label" />
    </div>
    <div class="form-field-content">
      <slot />
      <div class="form-field-note" v-if="$slots.note">
        <slot name="note" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelWidth: { default: "8rem" }
  }
};
</script>

<style scoped lang="scss">
.form-field {
  display: flex;
  .form-field-label,
  .form-field-content {
    text-align: left;
  }
  .form-field-label {
    vertical-align: top;
    flex-shrink: 0;
  }
  .form-field-content {
    flex-grow: 1;
  }
  .form-field-note {
    font-size: 0.8rem;
    color: rgb(128, 128, 128);
  }
}
</style>