<template>
  <span :class="`transaction-status-${statusCode.status}`">{{statusCode.name}}</span>
</template>

<script>
export default {
  props: ["statusCode"]
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.transaction-status-succeeded {
  color: $active-control-color;
}

.transaction-status-failed {
  color: $error-color;
}

.transaction-status-cancelled {
  color: rgb(128, 128, 128);
}
</style>