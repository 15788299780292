<template>
  <ModalDialog>
    <div class="simple-dialog-content">
      <p>
        <slot></slot>
      </p>
      <div class="buttons">
        <button
          v-for="button in buttons"
          :key="button.title"
          :type="button.type"
          :class="button.class || ''"
          @click="button.click"
        >{{button.title}}</button>
      </div>
    </div>
  </ModalDialog>
</template>

<script>
import ModalDialog from "../components/modal-dialog";

export default {
  props: ["buttons"],
  components: { ModalDialog }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.simple-dialog-content {
  margin: 2rem;
  .buttons {
    margin-top: 2rem;
    text-align: center;
    button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
</style>