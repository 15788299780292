<template>
  <div class="chevron">
    <div :class="`chevron-${direction}`">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    direction: { default: "left" }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.chevron {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  & > div {
    background-color: $active-control-color;
    width: 60%;
    height: 60%;
    & > div {
      background-color: white;
      width: 100%;
      height: 100%;
      transform: translate(-3px, -3px);
    }
  }
}

.chevron-left {
  transform: rotate(135deg);
}

.chevron-top {
  transform: rotate(225deg);
}

.chevron-right {
  transform: rotate(315deg);
}

.chevron-bottom {
  transform: rotate(45deg);
}
</style>