<template>
  <div class="pagination">
    <a class="button sub" :class="{disabled: current <= 1}" @click.prevent="previous()">&lt;</a>
    <a v-if="first > 1" class="button sub" @click.prevent="select(1)">1</a>
    <span class="ellipsis" v-if="first > 1 + 1">…</span>
    <a
      v-for="i in last - first + 1"
      :key="i"
      class="button"
      :class="{sub: current != first + i - 1}"
      @click.prevent="select(first + i - 1)"
    >{{first + i - 1}}</a>
    <span class="ellipsis" v-if="last < total - 1">…</span>
    <a v-if="last < total" class="button sub" @click.prevent="select(total)">{{total}}</a>
    <a class="button sub" :class="{disabled: current >= total}" @click.prevent="next()">&gt;</a>
  </div>
</template>

<script>
export default {
  props: {
    current: Number,
    total: Number,
    count: {
      type: Number,
      default: 7
    }
  },
  computed: {
    first() {
      return Math.max(1, this.current - Math.floor((this.count - 1) / 2));
    },
    last() {
      return Math.min(this.total, this.first + this.count - 1);
    }
  },
  methods: {
    select(page) {
      this.$emit("select", page);
    },
    previous() {
      if (this.current > 1) this.select(this.current - 1);
    },
    next() {
      if (this.current < this.total) this.select(this.current + 1);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.pagination {
  display: flex;
  justify-content: center;
  > * {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    margin: 0 0.5rem;
  }
  .ellipsis {
    color: $disabled-control-foreground-color;
    padding: 0.25rem 0;
  }
}
</style>