<template>
  <transition name="backdrop" appear>
    <div class="backdrop">
      <transition name="content" appear>
        <div class="content">
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  z-index: 19999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  position: absolute;
  background-color: white;
  border-radius: 0.25rem;
}

.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.1s;
}

.backdrop-enter,
.backdrop-leave-to {
  opacity: 0;
}

@keyframes content-scale {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.content-enter-active {
  animation: content-scale 0.1s;
}
</style>