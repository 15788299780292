import VueRouter from 'vue-router'

import LoginPage from './login'
import TransactionsPage from './transactions'
import TransactionPage from './transaction'
import BillingSummariesPage from './billing-summaries'
import UsersPage from './users'

const DEFAULT_PATH = '/transactions'

export default function ({ api, store }) {
    const routes = [
        {
            name: 'login',
            component: LoginPage
        },
        {
            name: 'transactions',
            component: TransactionsPage,
            children: [
                {
                    name: 'transaction',
                    path: ':transactionId/:statusCode',
                    component: TransactionPage
                }
            ]
        },
        {
            name: 'billingSummaries',
            component: BillingSummariesPage,
        },
        {
            name: 'users',
            component: UsersPage
        },
        {
            path: '*',
            redirect: DEFAULT_PATH
        }
    ]
    for (const route of routes) {
        if (!route.path && !route.redirect && route.name) route.path = `/${route.name}`
        route.meta = { component: route.component }
    }
    const router = new VueRouter({
        mode: 'history',
        routes
    })
    router.beforeEach(async (to, from, next) => {
        const meta = to.meta.component?.meta || {}
        if (!meta.public || meta.admin) {
            await store.dispatch('auth/loadCurrentUser')
            const currentUser = store.getters['auth/currentUser']
            if (!currentUser) {
                location.href = '/login'
                return;
            }
            if (meta.admin && !currentUser.isAdmin) return next('index')
        }
        next();
    })
    return router
}