export default function (api) {
    return {
        namespaced: true,
        state() {
            return {
                currentUser: null
            }
        },
        mutations: {
            setCurrentUser(state, currentUser) {
                if (currentUser) currentUser.isAdmin = currentUser.role == 'admin'
                state.currentUser = currentUser
            }
        },
        getters: {
            currentUser(state) {
                return state.currentUser
            }
        },
        actions: {
            async loadCurrentUser({ state, commit }) {
                if (!api.isLoggedIn) {
                    commit('setCurrentUser', null)
                    return
                }
                if (state.currentUser) return
                try {
                    commit('setCurrentUser', await api.get('/me'))
                } catch (err) {
                    commit('setCurrentUser', null)
                }
            },
            async login({ commit }, { email, password }) {
                const result = await api.login(email, password)
                if (result.isSuccess) {
                    commit('setCurrentUser', result.user)
                }
                return result
            },
            async logout({ commit }) {
                api.logout()
                commit('setCurrentUser', null)
            }
        }
    }
}
