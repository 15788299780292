<template>
  <div>
    <div v-if="showHeader && currentUser" class="header-menu">
      <header>
        <SiteLogo class="site-logo" />
        <span class="user-admin" v-if="currentUser.isAdmin">管理者</span>
        <span class="user-email">{{currentUser.email}}</span>
        <button type="button" @click="logout">ログアウト</button>
      </header>
      <div class="menu">
        <router-link to="/transactions">取引検索</router-link>
        <router-link to="/billingSummaries">集計参照</router-link>
        <router-link to="/users" v-if="currentUser.isAdmin">ユーザ管理</router-link>
      </div>
    </div>
    <div class="content">
      <router-view v-show="contentIsLoaded" />
    </div>
    <Loading :isLoading="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SiteLogo from "../components/site-logo";
import Loading from "../components/loading";

export default {
  components: { SiteLogo, Loading },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("loading", ["isLoading", "contentIsLoaded"]),
    showHeader() {
      return !this.$route.meta.public;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("login");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

$header-height: 7rem;

.header-menu {
  z-index: 10000;
  height: $header-height;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 0;
  background-color: rgb(248, 248, 248);
  border-bottom: solid 2px $active-control-color;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
}

header {
  display: flex;
  width: 100%;
  align-items: center;
}

.site-logo {
  flex: 1;
}

.user-admin,
.user-email {
  margin-right: 1rem;
}

.menu {
  display: flex;
  > a {
    display: inline-block;
    border: solid 1px $active-control-color;
    border-bottom: none;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 0.25rem 2rem;
    margin-right: 0.5rem;
    background-color: $sub-control-background-color;
    color: $sub-control-foreground-color;
    &.router-link-active {
      background-color: $active-control-color;
      color: $active-control-foreground-color;
    }
    &:hover:not(.router-link-active) {
      background-color: $sub-control-hover-color;
    }
  }
}

.content {
  padding: 1rem;
  padding-top: 2rem;
  margin-top: $header-height;
}
</style>