<template>
  <form class="search-form" :class="{grouped}" @submit.prevent="submit()">
    <slot />
    <div class="search-form-buttons" v-if="$slots.buttons">
      <slot name="buttons" />
    </div>
  </form>
</template>

<script>
export default {
  props: ["grouped"],
  methods: {
    submit() {
      this.$emit("submit");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.search-form-buttons {
  margin-top: 2rem;
}

.search-form.grouped {
  border: solid 1px $active-control-color;
  border-radius: 0.25rem;
  padding: 1rem;
  .search-form-buttons {
    text-align: center;
  }
}
</style>