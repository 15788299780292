import loading from "../plugins/loading"

export default function () {
    return {
        namespaced: true,
        state() {
            return {
                isLoading: false,
                contentIsLoaded: true
            }
        },
        mutations: {
            setLoading(state, isLoading) {
                state.isLoading = isLoading
                if (!isLoading) state.contentIsLoaded = true
            },
            startContentLoading(state) {
                state.contentIsLoaded = false
            }
        },
        getters: {
            isLoading(state) {
                return state.isLoading || !state.contentIsLoaded
            },
            contentIsLoaded(state) {
                return state.contentIsLoaded
            }
        },
        actions: {
            setLoading({ commit }, isLoading) {
                commit('setLoading', isLoading)
            },
            startContentLoading({ commit }) {
                commit('startContentLoading')
            }
        }
    }
}