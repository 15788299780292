<template>
  <PageContent>
    <template #title>集計参照</template>
    <SearchForm @submit="search">
      <div class="unit-radios">
        <label>
          <input type="radio" value="day" v-model="unit" />日次集計
        </label>
        <label>
          <input type="radio" value="month" v-model="unit" />月次集計
        </label>
      </div>
      <FormField v-if="currentUser.isAdmin" labelWidth="5rem">
        <template #label>企業</template>
        <select v-model="condition.company">
          <option :value="null"></option>
          <option v-for="company in companies" :key="company.id" :value="company">{{company.name}}</option>
        </select>
      </FormField>
      <FormField v-if="unit == 'day'" labelWidth="5rem">
        <template #label>対象日</template>
        <DatePicker v-model="condition.date" />
      </FormField>
      <FormField v-else labelWidth="5rem">
        <template #label>対象年月</template>
        <select class="search-form-month" v-model="conditionMonth">
          <option v-for="month in months" :key="month" :value="month">{{month}}</option>
        </select>
      </FormField>
      <template #buttons>
        <button type="submit" :disabled="!searchable">選択</button>
      </template>
    </SearchForm>
    <template v-if="summaries">
      <template v-if="summaries.length">
        <table class="record-table">
          <tr>
            <th>取引総数</th>
            <th>支払件数</th>
            <th>支払総額</th>
            <th>支払取消件数</th>
            <th>支払取消総額</th>
            <th>要求取消件数</th>
            <th>要求取消総額</th>
          </tr>
          <tr>
            <td>{{total.payment.count + total.reversal.count + total.requestreversal.count | currency}}件</td>
            <td>{{total.payment.count | currency}}件</td>
            <td>{{total.payment.amount | currency}}円</td>
            <td>{{total.reversal.count | currency}}件</td>
            <td>{{total.reversal.amount | currency}}円</td>
            <td>{{total.requestreversal.count | currency}}件</td>
            <td>{{total.requestreversal.amount | currency}}円</td>
          </tr>
        </table>
        <div class="result-header">
          <FormField labelWidth="6rem">
            <template #label>ペイメント</template>
            <select v-model="viewConfiguration.paymentMethod">
              <option :value="null"></option>
              <option
                v-for="paymentMethod in selectablePaymentMethods"
                :key="paymentMethod.id"
                :value="paymentMethod"
              >{{paymentMethod.name}}</option>
            </select>
          </FormField>
          <label>
            <input type="checkbox" v-model="viewConfigurationTradeName" />屋号別で表示する
          </label>
          <label>
            <input type="checkbox" v-model="viewConfigurationStore" />店舗別で表示する
          </label>
          <div class="result-header-csv">
            <DownloadLink
              class="button standard-button"
              :filename="csvFilename"
              :data="csvData"
            >CSVダウンロード</DownloadLink>
          </div>
        </div>
        <table class="record-table">
          <tr>
            <th v-if="viewConfiguration.tradeName">屋号</th>
            <th v-if="viewConfiguration.store">店舗</th>
            <th>ペイメント</th>
            <th>支払件数</th>
            <th>支払金額</th>
            <th>支払取消件数</th>
            <th>支払取消金額</th>
            <th>要求取消件数</th>
            <th>要求取消金額</th>
          </tr>
          <tr v-for="row in summaryRows" :key="row.keys">
            <td
              v-if="viewConfiguration.tradeName"
            >{{row.store && row.store.tradeName && row.store.tradeName.name}}</td>
            <td v-if="viewConfiguration.store">{{row.store && row.store.name}}</td>
            <td>{{row.paymentMethod && row.paymentMethod.name}}</td>
            <td>{{row.summary.payment.count | currency}}件</td>
            <td>{{row.summary.payment.amount | currency}}円</td>
            <td>{{row.summary.reversal.count | currency}}件</td>
            <td>{{row.summary.reversal.amount | currency}}円</td>
            <td>{{row.summary.requestreversal.count | currency}}件</td>
            <td>{{row.summary.requestreversal.amount | currency}}円</td>
          </tr>
        </table>
      </template>
      <p v-else class="no-data">該当するデータはありません</p>
    </template>
  </PageContent>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import * as Papa from "papaparse";
import PageContent from "../components/page-content";
import SearchForm from "../components/search-form";
import FormField from "../components/form-field";
import DatePicker from "../components/date-picker";
import DownloadLink from "../components/download-link";

const targetEndpointIds = new Set(["payment", "reversal", "requestreversal"]);

function emptySummary() {
  const result = {};
  targetEndpointIds.forEach(endpointId => {
    result[endpointId] = { count: 0, amount: 0 };
  });
  return result;
}

function addSummary(summary1, summary2) {
  const result = {};
  targetEndpointIds.forEach(endpointId => {
    result[endpointId] = {
      count: summary1[endpointId].count + summary2[endpointId].count,
      amount: summary1[endpointId].amount + summary2[endpointId].amount
    };
  });
  return result;
}

export default {
  metaInfo: {
    title: "日次集計"
  },
  components: { PageContent, SearchForm, FormField, DatePicker, DownloadLink },
  data: function() {
    return {
      unit: "day",
      companies: null,
      minDate: null,
      paymentMethods: null,
      condition: {
        company: null,
        date: new Date()
      },
      summaries: null,
      viewConfiguration: {
        tradeName: false,
        store: false,
        paymentMethod: null
      },
      csvUriCreated: null
    };
  },
  created() {
    this.$loading.loadContent();
  },
  async mounted() {
    await this.$loading(async () => {
      await Promise.all([this.loadCompanies(), this.loadPaymentMethods()]);
    });
  },
  watch: {
    async "condition.company"() {
      if (!this.currentUser.isAdmin) return;
      await this.$loading(async () => {
        this.minDate = null;
        if (this.condition.company) await this.loadMeta();
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    conditionMonth: {
      get() {
        return moment(this.condition.date).format("YYYY/MM");
      },
      set(value) {
        const [year, month] = value.split("/");
        this.condition.date = moment(this.condition.date)
          .year(year)
          .month(parseInt(month) - 1);
      }
    },
    months() {
      if (!this.minDate) return [moment(new Date()).format("YYYY/MM")];
      let current = moment(this.minDate);
      const max = moment(new Date()).format("YYYY/MM");
      const result = [];
      while (current.format("YYYY/MM") <= max) {
        result.push(current.format("YYYY/MM"));
        current = current.add(1, "months");
      }
      return result;
    },
    searchable() {
      return (
        (!this.currentUser.isAdmin || this.condition.company) &&
        this.condition.date
      );
    },
    conditionParams() {
      return {
        company: this.condition.company.id,
        date: moment(this.condition.date).format()
      };
    },
    total() {
      return this.aggregate(() => true)[0].summary;
    },
    summaryRows() {
      return this.aggregate(
        (entry1, entry2) =>
          (!this.viewConfiguration.tradeName ||
            entry1.store?.tradeName?.id == entry2.store?.tradeName?.id) &&
          (!this.viewConfiguration.store ||
            entry1.store?.storeId == entry2.store?.storeId) &&
          entry1.paymentMethod?.id == entry2.paymentMethod?.id
      ).filter(
        entry =>
          this.viewConfiguration.paymentMethod == null ||
          entry.paymentMethod?.id == this.viewConfiguration.paymentMethod.id
      );
    },
    selectablePaymentMethods() {
      if (this.paymentMethods) return this.paymentMethods;
      return this.aggregate(
        (entry1, entry2) => entry1.paymentMethod?.id == entry2.paymentMethod?.id
      ).map(entry => entry.paymentMethod);
    },
    viewConfigurationTradeName: {
      get() {
        return this.viewConfiguration.tradeName;
      },
      set(value) {
        this.viewConfiguration.tradeName = value;
        if (!value) this.viewConfiguration.store = false;
      }
    },
    viewConfigurationStore: {
      get() {
        return this.viewConfiguration.store;
      },
      set(value) {
        this.viewConfiguration.store = value;
        if (value) this.viewConfiguration.tradeName = true;
      }
    },
    csvFilename() {
      const base =
        this.unit == "day"
          ? `daily-${moment(this.condition.date).format("YYYYMMDD")}`
          : `monthly-${moment(this.condition.date).format("YYYYMM")}`;
      return `${base}-${this.condition.company.id}.csv`;
    },
    csvData() {
      const fields = [
        this.viewConfiguration.tradeName
          ? ["屋号", row => row.store.tradeName.name]
          : null,
        this.viewConfiguration.store ? ["店舗", row => row.store.name] : null,
        ["ペイメント", row => row.paymentMethod?.name],
        ["支払件数", row => row.summary.payment.count],
        ["支払金額", row => row.summary.payment.amount],
        ["支払取消件数", row => row.summary.reversal.count],
        ["支払取消金額", row => row.summary.reversal.amount],
        ["要求取消件数", row => row.summary.requestreversal.count],
        ["要求取消金額", row => row.summary.requestreversal.amount]
      ].filter(field => field);
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      return new Blob(
        [
          bom,
          Papa.unparse([
            fields.map(([title, _]) => title),
            ...this.summaryRows.map(row =>
              fields.map(([_, value]) => value(row))
            )
          ])
        ],
        { type: "text/csv" }
      );
    }
  },
  methods: {
    async loadCompanies() {
      if (this.currentUser.isAdmin) {
        this.companies = await this.$api.get("/companies");
      } else {
        this.condition.company = this.currentUser.company;
        await this.loadMeta();
      }
    },
    async loadPaymentMethods() {
      if (!this.currentUser.isAdmin) return;
      this.paymentMethods = await this.$api.get("/payment_methods");
    },
    async loadMeta() {
      const response = await this.$api.get("/billing_summaries/meta", {
        params: {
          company: this.condition.company.id
        }
      });
      this.minDate = response.minDate ? moment(response.minDate) : null;
    },
    search() {
      this.$loading(async () => {
        this.summaries = null;
        const data = await this.$api.get(
          `/billing_summaries/${this.unit == "day" ? "daily" : "monthly"}`,
          {
            params: this.conditionParams
          }
        );
        const summaries = [];
        for (const entry of data) {
          if (!entry.paymentMethod) continue;
          if (!targetEndpointIds.has(entry.endpoint?.id)) continue;
          let destination = summaries.find(
            summaryEntry =>
              summaryEntry.store?.tradeName?.id == entry.store?.tradeName?.id &&
              summaryEntry.store?.storeId == entry.store?.storeId &&
              summaryEntry.paymentMethod?.id == entry.paymentMethod?.id
          );
          if (!destination) {
            destination = {
              store: entry.store,
              paymentMethod: entry.paymentMethod,
              summary: emptySummary()
            };
            summaries.push(destination);
          }
          destination.summary[entry.endpoint.id].count += entry.count;
          destination.summary[entry.endpoint.id].amount += entry.amount;
        }
        this.summaries = summaries;
      });
    },
    aggregate(grouping) {
      const result = [];
      for (const entry of this.summaries) {
        let destination = result.find(resultEntry =>
          grouping(resultEntry, entry)
        );
        if (!destination) {
          destination = {
            store: entry.store,
            paymentMethod: entry.paymentMethod,
            summary: emptySummary()
          };
          result.push(destination);
        }
        destination.summary = addSummary(destination.summary, entry.summary);
      }
      return result;
    }
  }
};
</script>

<style scoped lang="scss">
.search-form {
  margin-bottom: 2rem;
  .form-field,
  .unit-radios {
    margin-bottom: 1rem;
  }
  .unit-radios {
    label {
      margin-right: 1rem;
    }
  }
  .search-form-month {
    min-width: 6rem;
  }
}

.result-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  line-height: 1.8rem;
  > * {
    margin-right: 2rem;
  }
  .result-header-csv {
    flex: 1;
    .button {
      width: 12rem;
    }
  }
}

.no-data {
  text-align: center;
}
</style>
