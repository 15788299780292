import Vuex from 'vuex'

import Auth from './auth'
import Loading from './loading'

export default function ({ api }) {
    return new Vuex.Store({
        modules: {
            auth: Auth(api),
            loading: Loading()
        }
    })
}