import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=38346e78&scoped=true"
import script from "./loading.vue?vue&type=script&lang=js"
export * from "./loading.vue?vue&type=script&lang=js"
import style0 from "./loading.vue?vue&type=style&index=0&id=38346e78&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38346e78",
  null
  
)

export default component.exports