<template>
  <input type="checkbox" v-model="isSelected" />
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: ["options", "value"],
  computed: {
    isSelected: {
      get() {
        return !this.options.find(
          option => !this.value.find(selected => selected == option)
        );
      },
      set(value) {
        this.$emit("change", value ? this.options.concat() : []);
      }
    }
  }
};
</script>