import Vue from 'vue/dist/vue.esm.js'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueMeta from 'vue-meta'

import Store from '../store/store'
import Router from '../pages/router'

import Layout from '../layouts/layout'

import Api from '../plugins/api'
import Loading from '../plugins/loading'
import CurrencyFilter from '../plugins/currency-filter'
import DateFilter from '../plugins/date-filter'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueMeta)
Vue.use(Api)
Vue.use(Loading)
Vue.use(CurrencyFilter)
Vue.use(DateFilter)

document.addEventListener('DOMContentLoaded', async () => {
  const api = new Api({
    authenticationRequiredHandler() {
      location.href = '/login'
    }
  })
  const store = Store({ api })
  const router = Router({ api, store })
  const metaInfo = {
    titleTemplate(chunk) {
      return chunk ? `${chunk} | ValueGateway` : 'ValueGateway'
    }
  }
  const app = new Vue({
    render: h => h(Layout),
    router,
    store,
    metaInfo,
    api
  }).$mount('#content-root')
})
