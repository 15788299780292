import { render, staticRenderFns } from "./chevron.vue?vue&type=template&id=f36c08ac&scoped=true"
import script from "./chevron.vue?vue&type=script&lang=js"
export * from "./chevron.vue?vue&type=script&lang=js"
import style0 from "./chevron.vue?vue&type=style&index=0&id=f36c08ac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36c08ac",
  null
  
)

export default component.exports