import { render, staticRenderFns } from "./billing-summaries.vue?vue&type=template&id=4516082c&scoped=true"
import script from "./billing-summaries.vue?vue&type=script&lang=js"
export * from "./billing-summaries.vue?vue&type=script&lang=js"
import style0 from "./billing-summaries.vue?vue&type=style&index=0&id=4516082c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4516082c",
  null
  
)

export default component.exports