<template>
  <transition name="loading" appear>
    <div class="backdrop" v-if="isLoading">
      <div class="indicator">
        <div v-for="index in 12" :key="index" class="indicator-part">
          <div class="indicator-dot"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["isLoading"]
};
</script>

<style scoped lang="scss">
.backdrop {
  position: fixed;
  z-index: 19999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  width: 5rem;
  height: 5rem;
}

@keyframes loading-indicator {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.indicator-part {
  position: absolute;
  width: 5rem;
  height: 5rem;
  animation: loading-indicator 0.6s ease-in 0s infinite alternate;
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      transform: rotateZ(#{$i * 30}deg);
      animation-delay: #{($i - 1) * 0.1}s;
    }
  }
}

.indicator-dot {
  background-color: white;
  width: 0.5rem;
  height: 1rem;
  border-radius: 0.2rem;
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0%);
}

.loading-enter-active,
.loading-leave-active {
  transition: opacity 0.1s;
}

.loading-enter,
.loading-leave-to {
  opacity: 0;
}
</style>