<template>
  <div class="login-content">
    <SiteLogo />
    <form @submit.prevent="login">
      <FormField>
        <template #label>メールアドレス</template>
        <input type="text" v-model="email" />
      </FormField>
      <FormField>
        <template #label>パスワード</template>
        <input type="password" v-model="password" />
      </FormField>
      <button type="submit" :disabled="!isSubmittable">ログイン</button>
    </form>
    <p class="error-message" v-if="error">メールアドレスかパスワードが正しくありません</p>
  </div>
</template>

<script>
import SiteLogo from "../components/site-logo";
import FormField from "../components/form-field";

export default {
  meta: {
    public: true
  },
  components: { SiteLogo, FormField },
  data: function() {
    return {
      email: "",
      password: "",
      error: null
    };
  },
  computed: {
    isSubmittable() {
      return this.email.length && this.password.length;
    }
  },
  methods: {
    async login() {
      await this.$loading(async () => {
        this.error = null;
        const result = await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password
        });
        if (result.isSuccess) {
          this.$router.push("index");
        } else {
          this.error = result.data.error;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.login-content {
  width: 100%;
  text-align: center;
  form button {
    margin-top: 1rem;
  }
}

.site-logo {
  margin-bottom: 2rem;
}

.form-field {
  margin-bottom: 1rem;
}

form {
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  input {
    width: 100%;
  }
}

.error-message {
  margin-top: 1rem;
}
</style>
