<template>
  <div class="date-picker">
    <input type="text" :value="text" readonly @focus="showCalendar" />
    <div class="date-picker-backdrop" v-show="calendarIsShown" @click="closeCalendar" />
    <div class="calendar" v-show="calendarIsShown">
      <h4>
        <span class="year">
          <span @click="previousYear">
            <Chevron direction="left" />
          </span>
          <span class="header-value">{{year}}</span>
          <span class="header-unit">年</span>
          <span @click="nextYear">
            <Chevron direction="right" />
          </span>
        </span>
        <span class="month">
          <span @click="previousMonth">
            <Chevron direction="left" />
          </span>
          <span class="header-value">{{month}}</span>
          <span class="header-unit">月</span>
          <span @click="nextMonth">
            <Chevron direction="right" />
          </span>
        </span>
      </h4>
      <div class="weekdays">
        <div>日</div>
        <div>月</div>
        <div>火</div>
        <div>水</div>
        <div>木</div>
        <div>金</div>
        <div>土</div>
      </div>
      <div class="days">
        <div
          v-for="day in days"
          :key="day"
          :class="{
                'day': isInMonth(day),
                'current-date': isCurrentDate(day)
            }"
          @click="selectDay(day)"
        >
          <template v-if="isInMonth(day)">{{day}}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Chevron from "../components/chevron";

export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: ["value"],
  components: { Chevron },
  data() {
    const date = moment(this.value || new Date());
    return {
      year: date.year(),
      month: date.month() + 1,
      calendarIsShown: false
    };
  },
  computed: {
    text() {
      return this.value ? moment(this.value).format("YYYY/MM/DD") : "";
    },
    startOfMonth() {
      return moment(new Date(this.year, this.month - 1));
    },
    days() {
      const start = this.startOfMonth.day();
      const days = [];
      for (let i = 1; i <= 7 * 6; ++i) days.push(i - start);
      return days;
    }
  },
  methods: {
    showCalendar() {
      if (this.calendarIsShown) return;
      const date = moment(this.value || new Date());
      this.year = date.year();
      this.month = date.month() + 1;
      this.calendarIsShown = true;
    },
    previousYear() {
      if (this.year <= 1900) return;
      this.year--;
    },
    nextYear() {
      this.year++;
    },
    previousMonth() {
      if (this.month == 1) {
        this.previousYear();
        this.month = 12;
      } else {
        this.month--;
      }
    },
    nextMonth() {
      if (this.month == 12) {
        this.nextYear();
        this.month = 1;
      } else {
        this.month++;
      }
    },
    closeCalendar() {
      this.calendarIsShown = false;
    },
    isInMonth(day) {
      return day >= 1 && day <= this.startOfMonth.daysInMonth();
    },
    isCurrentDate(day) {
      if (!this.isInMonth(day) || !this.value) return false;
      const value = moment(this.value);
      return (
        value.year() == this.year &&
        value.month() + 1 == this.month &&
        value.date() == day
      );
    },
    selectDay(day) {
      if (this.isInMonth(day)) {
        this.$emit("change", new Date(this.year, this.month - 1, day));
        this.closeCalendar();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.date-picker {
  display: inline-block;
  position: relative;
}

input {
  width: 8rem;
}

.calendar {
  padding: 0.5rem;
  background-color: white;
  position: absolute;
  left: 0;
  border: solid 1px $active-control-color;
  h4 {
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    border-bottom: solid 1px $active-control-color;
    padding-bottom: 0.5rem;
    .year {
      margin-right: 1rem;
    }
    .header-unit {
      color: rgb(128, 128, 128);
      font-size: 0.8rem;
      font-weight: bold;
    }
    .chevron:first-child {
      margin-right: 0.5rem;
    }
    .chevron:last-child {
      margin-left: 0.5rem;
    }
  }
  .weekdays,
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    > * {
      display: flex;
      border-radius: 0.5rem;
      width: 2.5rem;
      height: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
  .weekdays {
    color: rgb(128, 128, 128);
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .day {
    cursor: pointer;
    &:hover:not(.current-date) {
      background-color: $sub-control-hover-color;
    }
  }
  .current-date {
    background-color: $active-control-color;
    color: $active-control-foreground-color;
  }
}

.date-picker-backdrop {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.calendar {
  z-index: 9999;
}
</style>