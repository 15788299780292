<template>
  <span>
    <input type="text" maxlength="2" v-model="hour" />:
    <input type="text" maxlength="2" v-model="minute" />
  </span>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: { required: true }
  },
  computed: {
    hour: {
      get() {
        return String(this.value.hour);
      },
      set(value) {
        const hour = parseInt(value) || 0;
        this.$emit("input", {
          hour: hour < 0 ? 0 : hour > 23 ? 23 : hour,
          minute: this.value.minute
        });
      }
    },
    minute: {
      get() {
        return String(this.value.minute);
      },
      set(value) {
        const minute = parseInt(value) || 0;
        this.$emit("input", {
          hour: this.value.hour,
          minute: minute < 0 ? 0 : minute > 59 ? 59 : minute
        });
      }
    }
  }
};
</script>

<style scoped>
input {
  width: 2rem;
  text-align: right;
}
</style>