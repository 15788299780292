<template>
  <h1 class="site-logo">ValueGateway</h1>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

h1 {
  color: $primary-color;
}
</style>