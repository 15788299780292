<template>
  <div>
    <PageContent v-if="!detailIsShown">
      <template #title>取引検索</template>
      <SearchForm :grouped="true" @submit="search">
        <FormField labelWidth="6rem">
          <template #label>日時</template>
          <div>
            <DatePicker v-model="condition.registeredAt.from.date" />
            <TimeInput v-model="condition.registeredAt.from.time" />
            <span>〜</span>
            <DatePicker v-model="condition.registeredAt.to.date" />
            <TimeInput v-model="condition.registeredAt.to.time" />
          </div>
        </FormField>
        <div class="group-condition">
          <FormField labelWidth="6rem" v-if="currentUser.isAdmin">
            <template #label>企業</template>
            <select v-model="condition.company">
              <option :value="null"></option>
              <option
                v-for="company in companies"
                :key="company.id"
                :value="company"
              >{{company.name}}</option>
            </select>
          </FormField>
          <FormField labelWidth="6rem">
            <template #label>屋号</template>
            <select v-model="condition.tradeName">
              <option :value="null"></option>
              <option
                v-for="tradeName in tradeNames"
                :key="tradeName.id"
                :value="tradeName"
              >{{tradeName.name}}</option>
            </select>
          </FormField>
          <FormField labelWidth="6rem">
            <template #label>店舗</template>
            <select v-model="condition.store">
              <option :value="null"></option>
              <option v-for="store in stores" :key="store.id" :value="store">{{store.name}}</option>
            </select>
          </FormField>
        </div>
        <FormField labelWidth="6rem">
          <template #label>ペイメント</template>
          <div class="checkboxes">
            <label>
              <SelectAllCheckbox v-model="condition.paymentMethods" :options="paymentMethods" />全て
            </label>
            <label v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
              <input type="checkbox" v-model="condition.paymentMethods" :value="paymentMethod" />
              {{paymentMethod.name}}
            </label>
          </div>
        </FormField>
        <FormField labelWidth="6rem">
          <template #label>取引種別</template>
          <div class="checkboxes">
            <label>
              <SelectAllCheckbox v-model="condition.endpoints" :options="endpoints" />全て
            </label>
            <label v-for="endpoint in endpoints" :key="endpoint.id">
              <input type="checkbox" v-model="condition.endpoints" :value="endpoint" />
              {{endpoint.name}}
            </label>
          </div>
        </FormField>
        <FormField labelWidth="6rem">
          <template #label>要求番号</template>
          <input type="text" class="request-number" v-model="condition.requestNumber" />
          <template #note>※入力がある場合、こちらの条件が優先されます</template>
        </FormField>
        <template #buttons>
          <button type="submit" :disabled="!searchable">取引検索</button>
        </template>
      </SearchForm>
      <template v-if="transactions">
        <template v-if="transactions.length">
          <div class="result-header">
            <div class="result-header-count">検索結果：{{transactionCount}}件</div>
            <div class="result-header-csv">
              <a class="button standard-button" :href="csvUri" download>CSVダウンロード</a>
            </div>
            <div class="result-header-limits">
              ページ表示件数：
              <span v-for="n in [20, 50, 100, 200]" :key="n">
                <span v-if="n == limit">{{n}}件</span>
                <a v-else @click.prevent="limit = n">{{n}}件</a>
              </span>
            </div>
          </div>
          <table class="record-table">
            <tr>
              <th></th>
              <th>送信日時</th>
              <th>取引ステータス</th>
              <th>要求番号</th>
              <th>取引種別</th>
              <th>取引額</th>
              <th>支払方法</th>
              <th v-if="currentUser.isAdmin">企業</th>
              <th>屋号</th>
              <th>店舗</th>
              <th>端末ID</th>
            </tr>
            <tr
              v-for="transaction in transactions"
              :key="`${transaction.transactionId}/${transaction.statusCode.value}`"
            >
              <td>
                <router-link
                  :to="{name: 'transaction', params: {transactionId: transaction.transactionId, statusCode: transaction.statusCode.value}}"
                >詳細</router-link>
              </td>
              <td>{{transaction.transmissionTime | normalDatetime}}</td>
              <td>
                <TransactionStatus :statusCode="transaction.statusCode" />
              </td>
              <td>{{transaction.requestNumber}}</td>
              <td>{{transaction.endpoint && transaction.endpoint.name}}</td>
              <td>{{transaction.amount | currency}}円</td>
              <td>{{transaction.paymentMethod && transaction.paymentMethod.name}}</td>
              <td
                v-if="currentUser.isAdmin"
              >{{transaction.store && transaction.store.tradeName && transaction.store.tradeName.company && transaction.store.tradeName.company.name}}</td>
              <td>{{transaction.store && transaction.store.tradeName && transaction.store.tradeName.name}}</td>
              <td>{{transaction.store && transaction.store.name}}</td>
              <td>{{transaction.terminalNumber}}</td>
            </tr>
          </table>
          <Pagination :current="page" :total="totalPages" @select="selectPage" />
        </template>
        <p v-else class="no-transactions">該当する取引はありません</p>
      </template>
    </PageContent>
    <router-view v-if="detailIsShown" v-show="contentIsLoaded" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import PageContent from "../components/page-content";
import SearchForm from "../components/search-form";
import FormField from "../components/form-field";
import DatePicker from "../components/date-picker";
import TimeInput from "../components/time-input";
import SelectAllCheckbox from "../components/select-all-checkbox";
import Pagination from "../components/pagination";
import TransactionStatus from "../components/transaction-status";

function formatConditionTime(
  { date, time: { hour, minute } },
  second,
  millisecond
) {
  return moment(date)
    .hour(hour)
    .minute(minute)
    .second(second)
    .millisecond(millisecond)
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
}

export default {
  metaInfo: {
    title: "取引検索"
  },
  components: {
    PageContent,
    SearchForm,
    FormField,
    DatePicker,
    TimeInput,
    SelectAllCheckbox,
    Pagination,
    TransactionStatus
  },
  data: function() {
    return {
      companies: [],
      tradeNames: [],
      stores: [],
      paymentMethods: [],
      endpoints: [],
      condition: {
        registeredAt: {
          from: {
            date: new Date(),
            time: { hour: 0, minute: 0 }
          },
          to: {
            date: new Date(),
            time: { hour: 23, minute: 59 }
          }
        },
        paymentMethods: [],
        endpoints: [],
        company: null,
        tradeName: null,
        store: null,
        requestNumber: null
      },
      page: 1,
      limit: 20,
      transactions: null,
      transactionCount: null
    };
  },
  created() {
    this.$loading.loadContent();
  },
  async mounted() {
    await this.$loading(async () => {
      await Promise.all([
        this.loadCompanies(),
        this.loadPaymentMethodds(),
        this.loadEndpoints()
      ]);
    });
  },
  watch: {
    async "condition.company"() {
      if (!this.currentUser.isAdmin) return;
      await this.$loading(async () => {
        this.condition.tradeName = null;
        this.tradeNames = [];
        if (this.condition.company) await this.loadTradeNames();
      });
    },
    async "condition.tradeName"() {
      await this.$loading(async () => {
        this.condition.store = null;
        this.stores = [];
        if (this.condition.tradeName) {
          this.stores = await this.$api.get(
            `/companies/${this.condition.company.id}/trade_names/${this.condition.tradeName.id}/stores`
          );
        }
      });
    },
    async limit() {
      this.page = 1;
      this.refresh();
    },
    async page() {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("loading", ["contentIsLoaded"]),
    detailIsShown() {
      return this.$route.name == "transaction";
    },
    searchable() {
      return (
        (this.condition.company &&
          this.condition.paymentMethods.length &&
          this.condition.endpoints.length) ||
        this.condition.requestNumber
      );
    },
    totalPages() {
      return Math.ceil(this.transactionCount / this.limit);
    },
    conditionParams() {
      return this.condition.requestNumber
        ? {
            requestNumber: this.condition.requestNumber
          }
        : {
            fromRegisteredAt: formatConditionTime(
              this.condition.registeredAt.from,
              0,
              0
            ),
            toRegisteredAt: formatConditionTime(
              this.condition.registeredAt.to,
              59,
              999
            ),
            paymentMethods: this.condition.paymentMethods.map(
              paymentMethod => paymentMethod.id
            ),
            endpoints: this.condition.endpoints.map(endpoint => endpoint.id),
            company: this.condition.company?.id,
            tradeName: this.condition.tradeName?.id,
            store: this.condition.store?.storeId
          };
    },
    csvUri() {
      return this.$api.getUri("/transactions.csv", {
        params: this.conditionParams
      });
    }
  },
  methods: {
    async loadCompanies() {
      if (this.currentUser.isAdmin) {
        this.companies = await this.$api.get("/companies");
      } else {
        this.condition.company = this.currentUser.company;
        await this.loadTradeNames();
      }
    },
    async loadTradeNames() {
      this.tradeNames = await this.$api.get(
        `/companies/${this.condition.company.id}/trade_names`
      );
    },
    async loadPaymentMethodds() {
      this.paymentMethods = await this.$api.get("/payment_methods");
      this.condition.paymentMethods = this.paymentMethods.concat();
    },
    async loadEndpoints() {
      this.endpoints = await this.$api.get("/endpoints");
      this.condition.endpoints = this.endpoints.concat();
    },
    async refresh() {
      await this.$loading(async () => {
        this.transactions = null;
        const response = await this.$api.get("/transactions", {
          params: {
            ...this.conditionParams,
            page: this.page,
            per: this.limit
          },
          withMeta: true
        });
        this.transactions = response.data;
        this.transactionCount = response.meta.count;
      });
    },
    async search() {
      this.page = 1;
      this.refresh();
    },
    selectPage(page) {
      this.page = page;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.search-form {
  margin-bottom: 2rem;
}

.form-field {
  margin-bottom: 1rem;
}

.group-condition {
  display: flex;
  > * {
    margin-right: 4rem;
  }
  select {
    min-width: 12rem;
  }
}

.request-number {
  width: 32rem;
}

.checkboxes {
  display: grid;
  grid-template-columns: repeat(auto-fill, 16rem);
  align-items: center;
  label {
    line-height: 1.8rem;
  }
}

.result-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .result-header-count {
    width: 16rem;
  }
  .result-header-csv {
    flex: 1;
    .button {
      width: 12rem;
    }
  }
  .result-header-limits {
    > span:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.pagination {
  margin-top: 1rem;
}

.no-transactions {
  text-align: center;
}
</style>
