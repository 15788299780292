import axios from 'axios'
import moment from 'moment'

function cookieValue(name) {
    return document.cookie.split(';')
        .map(entry => {
            const pair = entry.split('=')
            return {
                name: pair[0].trim(),
                value: pair[1]?.trim() ?? ''
            }
        })
        .find(entry => entry.name == name)?.value
}

function setCookie(name, value, lifetime) {
    const expires = lifetime == undefined ? undefined : moment().add(lifetime, 'minutes').toDate().toGMTString();
    document.cookie = `${name}=${value}; secure` + (`; expires=${expires}` || '')
}

export default class Api {
    constructor({ baseURL, authenticationRequiredHandler }) {
        this.axios = axios.create({
            baseURL: '/api'
        })
        this.axios.interceptors.request.use(config => {
            if (this.accessToken) config.headers['Authorization'] = `Bearer ${this.accessToken}`
            if (this.refreshToken) this.extendRefreshTokenLifetime()
            return config
        })
        this.authenticationRequiredHandler = authenticationRequiredHandler
    }

    get accessToken() {
        return cookieValue('apiAccessToken')
    }

    saveAccessToken(value) {
        setCookie('apiAccessToken', value)
    }

    get refreshToken() {
        return cookieValue('apiRefreshToken')
    }

    saveRefreshToken(value) {
        setCookie('apiRefreshToken', value, 60)
    }

    extendRefreshTokenLifetime() {
        this.saveRefreshToken(this.refreshToken)
    }

    async refreshAccessToken() {
        if (!this.refreshToken) return;
        const result = await this.post('/login_sessions/refresh', { refreshToken: this.refreshToken })
        this.saveAccessToken(result.accessToken)
    }

    get isLoggedIn() {
        return !!this.refreshToken
    }

    async login(email, password) {
        try {
            const result = await this.post('/login_sessions', { email, password })
            this.saveAccessToken(result.accessToken)
            this.saveRefreshToken(result.refreshToken)
            return { isSuccess: true, user: result.user }
        } catch (err) {
            return { isSuccess: false, data: err.response.data }
        }
    }

    async logout() {
        await this.delete('/login_sessions')
        this.saveAccessToken('')
        this.saveRefreshToken('')
    }

    static isAuthenticationRequired(err) {
        const response = err.response
        return response?.status == 401 && response?.data?.error == 'authentication_required'
    }

    async requestWithAuthRetry(method, path, options) {
        try {
            return (await this.axios[method](path, options))
        } catch (err) {
            if (!Api.isAuthenticationRequired(err)) throw err;
            if (!this.refreshToken) {
                this.authenticationRequiredHandler?.apply(this.app)
                throw err;
            }
            await this.refreshAccessToken()
            try {
                return (await this.axios[method](path, options))
            } catch (err) {
                if (Api.isAuthenticationRequired(err)) {
                    this.authenticationRequiredHandler?.apply(this.app)
                }
                throw err;
            }
        }
    }

    async request(method, path, options) {
        const response = await this.requestWithAuthRetry(method, path, options);
        return options?.withMeta ? {
            data: response.data,
            meta: {
                count: response.headers['x-total-count']
            }
        } : response.data
    }

    getUri(path, options) {
        return this.axios.getUri({
            url: `${this.axios.defaults.baseURL}${path}`,
            ...options
        })
    }
}

['get', 'post', 'put', 'delete'].forEach((name) => {
    Api.prototype[name] = async function (path, options) {
        return await this.request(name, path, options);
    }
})

Api.install = function (Vue, options) {
    Object.defineProperty(Vue.prototype, '$api', {
        get() {
            if (this.$options?.api) {
                const app = this;
                return Object.create(this.$options.api, {
                    app: {
                        get() {
                            return app
                        }
                    }
                })
            }
            return this.$options.parent?.$api
        }
    });
}
