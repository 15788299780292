<template>
  <div>
    <h2 v-if="$slots.title">
      <slot name="title" />
    </h2>
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
h2 {
  margin-bottom: 2rem;
}
</style>