<template>
  <a v-if="supportsObjectURL" :download="filename" :href="url">
    <slot />
  </a>
  <a v-else @click="download">
    <slot />
  </a>
</template>

<script>
export default {
  props: ["filename", "data"],
  data() {
    return {
      urlCreated: null
    };
  },
  computed: {
    supportsObjectURL() {
      return !window.navigator.msSave;
    },
    url() {
      if (this.urlCreated) URL.revokeObjectURL(this.urlCreated);
      this.urlCreated = URL.createObjectURL(this.data);
      return this.urlCreated;
    }
  },
  methods: {
    download() {
      return window.navigator.msSave(this.data, this.filename);
    }
  }
};
</script>