<template>
  <PageContent>
    <template #title>取引履歴詳細</template>
    <template v-if="transaction">
      <p class="return-link">
        <router-link to="/transactions">一覧へ戻る</router-link>
      </p>
      <h3>
        要求番号：
        <span>{{transaction.requestNumber}}</span>
      </h3>
      <div class="tables">
        <table class="record-table">
          <tr>
            <th>処理ステータス</th>
            <td>
              <TransactionStatus :statusCode="transaction.statusCode" />
            </td>
          </tr>
          <tr>
            <th>対象要求番号</th>
            <td>{{transaction.targetRequestNumber}}</td>
          </tr>
          <tr>
            <th>イシュア要求ID</th>
            <td>{{transaction.issuerRequestId}}</td>
          </tr>
          <tr>
            <th>対象イシュア要求ID</th>
            <td>{{transaction.targetIssuerRequestId}}</td>
          </tr>
          <tr>
            <th>取引種別</th>
            <td>{{transaction.endpoint && transaction.endpoint.name}}</td>
          </tr>
          <tr>
            <th>企業ID</th>
            <td>{{transaction.store && transaction.store.tradeName && transaction.store.tradeName.id}}</td>
          </tr>
          <tr>
            <th>企業名</th>
            <td>{{transaction.store && transaction.store.tradeName && transaction.store.tradeName.company && transaction.store.tradeName.company.name}}</td>
          </tr>
          <tr>
            <th>屋号名</th>
            <td>{{transaction.store && transaction.store.tradeName && transaction.store.tradeName.name}}</td>
          </tr>
          <tr>
            <th>店舗ID</th>
            <td>{{transaction.store && transaction.store.storeId}}</td>
          </tr>
          <tr>
            <th>店舗名</th>
            <td>{{transaction.store && transaction.store.name}}</td>
          </tr>
          <tr>
            <th>端末ID</th>
            <td>{{transaction.terminalNumber}}</td>
          </tr>
          <tr>
            <th>送信日時</th>
            <td>{{transaction.transmissionTime | normalDatetime}}</td>
          </tr>
        </table>
        <table class="record-table">
          <tr>
            <th>取引額</th>
            <td>{{transaction.amount | currency}}円</td>
          </tr>
          <tr>
            <th>支払方法</th>
            <td>{{transaction.paymentMethod && transaction.paymentMethod.name}}</td>
          </tr>
          <tr>
            <th>再仕向先区分</th>
            <td>{{transaction.redirectDestination && transaction.redirectDestination.name}}</td>
          </tr>
          <tr>
            <th>登録日時</th>
            <td>{{transaction.registeredAt | normalDatetime}}</td>
          </tr>
        </table>
      </div>
    </template>
  </PageContent>
</template>

<script>
import { mapGetters } from "vuex";
import PageContent from "../components/page-content";
import TransactionStatus from "../components/transaction-status";

export default {
  metaInfo: {
    title: "取引履歴詳細"
  },
  components: {
    PageContent,
    TransactionStatus
  },
  data() {
    return {
      transaction: null
    };
  },
  created() {
    this.$loading.loadContent();
  },
  async mounted() {
    await this.$loading(async () => {
      this.transaction = await this.$api.get(
        `/transactions/${this.$route.params.transactionId}/${this.$route.params.statusCode}`
      );
    });
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/variables.scss";

.return-link {
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.tables {
  display: flex;
  :first-child {
    margin-right: 1rem;
  }
}

.record-table {
  width: 38rem;
  th {
    width: 12rem;
  }
}
</style>